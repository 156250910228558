import { Injectable } from '@angular/core';

import {
  CreateOneCredentialGQL,
  CreateOneOrganizationGQL,
  CreateOrganizationDto,
  CredentialDeleteInput,
  CredentialInput,
  DeleteOneCredentialGQL,
  DeleteOneOrganizationGQL,
  OrganizationFilter,
  OrganizationsGQL,
  OrganizationSortFields,
  SortDirection,
  UpdateOneCredentialGQL,
  UpdateOneOrganizationGQL,
  UpdateOrganizationDto
} from 'src/app/graphql/frontend-data-graphql';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  constructor(
    private findManyGQL: OrganizationsGQL,
    private createGQL: CreateOneOrganizationGQL,
    private updateGQL: UpdateOneOrganizationGQL,
    private deleteGQL: DeleteOneOrganizationGQL,
    private createCredentialGQL: CreateOneCredentialGQL,
    private updateCredentialGQL: UpdateOneCredentialGQL,
    private deleteCredentialGQL: DeleteOneCredentialGQL
  ) {}

  findMany(filter: OrganizationFilter = {}) {
    return this.findManyGQL.fetch({
      filter,
      sorting: { field: OrganizationSortFields.Name, direction: SortDirection.Asc }
    });
  }

  create(input: CreateOrganizationDto) {
    return this.createGQL.mutate({ input });
  }

  update(id: string, update: UpdateOrganizationDto) {
    return this.updateGQL.mutate({ id, update });
  }

  delete(id: string) {
    return this.deleteGQL.mutate({ id });
  }

  createCredential(input: CredentialInput) {
    return this.createCredentialGQL.mutate({ input });
  }

  updateCredential(input: CredentialInput) {
    return this.updateCredentialGQL.mutate({ input });
  }

  deleteCredential(input: CredentialDeleteInput) {
    return this.deleteCredentialGQL.mutate({ input });
  }
}
